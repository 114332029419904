html,
body {
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  background-color: #1a2236;
}

.container {
  margin: 0;
  padding: 0;
  max-width: 100%;
  height: 100%;
}

.nav-container {
  margin: auto;
}

a {
  text-decoration: none;
}

#root, .main-wrapper {
    overflow: visible !important;
}